<template>
  <div>
    <div v-if="restricted" class="restrict-main">
      <img class="hand" :src="hand" alt="hand" />
      <div class="bold">Not so fast...</div>
      <div class="restrict-explanation">
        Let’s take this one step at a time! Complete the preceding section of
        tasks, then come back here to continue your Journey.
      </div>
      <div @click="emit('tabClick', path, '')" class="restrict-button">HR</div>
    </div>
    <section v-if="!restricted">
      <div v-if="showCompleteModal" class="uncheck-modal">
        <div>
          <div class="bold">Nice!</div>
          <div class="exlpanation">
            You’re one step closer to completing your onboarding material and
            finishing your house! Keep an eye on those notifications for updates
            from your leader.
          </div>
        </div>
        <div class="button-area">
          <div class="submit" @click="showCompleteModal = false">Continue</div>
        </div>
      </div>
      <div v-if="view === 'task'">
        <Task
          @changeStatus="
            (task, i, column) => updateStatus(task, i, column, topic)
          "
          @taskComplete="(task, i, column) => completeTask(task, i)"
          @click="view = 'setting-up'"
          :topic="topic"
          :task="currentTask"
        />
      </div>
      <div v-if="view === 'setting-up'">
        <div class="divider" />
        <Help
          :tasks="allTasks"
          v-if="showModal"
          @showModal="(i) => (showModal = i)"
          class="assistance-modal"
          @changeStatus="(selected, explanation) => handleAssistance(selected, explanation)"
        />
        <!-- <div class="months-blocks">
      <div v-for="course in courses" :key="course.month" class="month-block">
        <div class="month-number">
          <span class="month-number-text">Month</span>
          <span>{{ course.month }}</span>
        </div>
        <CircularProgress :value="course.month * 24" />
      </div>
    </div> -->
        <div class="months-blocks">
          <div class="month-block workday">
            <div class="month-number">
              <span class="month-number-text">Technology</span>
              <div class="completed">
                {{ technologyCompleted }}/{{ technology.length }} tasks complete
              </div>
            </div>
            <CircularProgress
              class="circle"
              :value="
                Math.floor((technologyCompleted / technology.length) * 100)
              "
            />
          </div>
          <div class="month-block badging">
            <div class="month-number">
              <span class="month-number-text">Logistics</span>
              <div class="completed">
                {{ logisticsCompleted }}/{{ logistics.length }} tasks complete
              </div>
            </div>
            <CircularProgress
              class="circle"
              :value="Math.floor((logisticsCompleted / logistics.length) * 100)"
            />
          </div>
        </div>
        <StatusKeyHelper />
        <div class="tasks">
          <div class="tasks-container month-tasks-block">
            <div class="tasks-block">
              <h4 class="month-header">Technology</h4>
              <div
                @click="taskClicked(task, 'Technology', index)"
                v-for="(task, index) in technology"
                :key="task.name"
                class="month-tasks-subblock"
              >
                <TaskStatusedItem
                  :task="task"
                  :name="task.name"
                  :status="task.status"
                  :disabled="Math.random() > 0.5"
                />
              </div>
            </div>
          </div>
          <div class="tasks-container month-tasks-block">
            <div class="tasks-block">
              <h4 class="month-header">Logistics</h4>
              <div
                @click="taskClicked(task, 'Logistics', index)"
                v-for="(task, index) in logistics"
                :key="task.name"
                class="month-tasks-subblock"
              >
                <TaskStatusedItem
                  :task="task"
                  :name="task.name"
                  :status="task.status"
                  :disabled="Math.random() > 0.5"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="button-area">
          <AssistanceButton v-if="showAssistanceButton" class="button" @click="showModal = true" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import CircularProgress from "@/components/CircularProgress";
import StatusKeyHelper from "@/components/StatusKeyHelper";
import splitTasksArrayByHalfs from "@/utils/splitTasksArrayByHalfs";
import TaskStatusedItem from "@/components/TaskStatusedItem";
import Help from "@/components/Help";
import Task from "@/views/Task";
import AssistanceButton from "@/components/AssistanceButton";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter, useRoute } from "vue-router";
import ChecklistAPI from "@/api/ChecklistAPI";
import hand from "@/assets/images/hand.png";
const showAssistanceButton = ref(true)
const restricted = ref(false);
const showCompleteModal = ref(false);
const allTasks = ref();
const uncheckModal = ref(false);
const currentMonth = ref(null);
const index = ref(null);

const router = useRouter();
const technologyCompleted = ref(2);
const logisticsCompleted = ref(1);
const showModal = ref(false);
const currentTask = ref(null);
const currentIndex = ref(null);
const logistics = ref();
const technology = ref();
const topic = ref(null);
const emit = defineEmits({
  tabClick: (path) => typeof path === "string",
  signOut: () => true,
});
const handleAssistance = async (tasks, explanation)=>{
  const ids = []
  tasks.forEach(async task=>{
    ids.push(task.id)
    const update = {
      task_id: task.id,
      status: 'attention-required',
      subtask_status: 'attention-required'
    }
   await ChecklistAPI.updateTask(update)
    ids.push(task.id)
  })
  let expl
  if(explanation) expl = explanation
  else expl = 'n/a'
  const payload = {
    problem: expl,
    status: 'active',
    subtask_ids: [],
    task_ids: ids
  }
  await ChecklistAPI.updateAssistance(payload)
  await getTasks();

}
const path = ref();
const route = useRoute();

onMounted(async () => {
  await getTasks();

  const checklist = await ChecklistAPI.getChecklist();
  const technologyList = checklist.find((task) => task.name === "Technology");
  const logisticsList = checklist.find((task) => task.name === "Logistics");
  const workdayList = checklist.find((task) => task.name === "Workday");
  const badgingList = checklist.find((task) => task.name === "Badging");
  const allHR = workdayList.tasks.concat(badgingList.tasks);
  allHR.forEach((item) => {
    console.log("prog::", item.progresses);
    if (!item.progresses) {
      restricted.value = true;
      path.value = "/hr";
    } else if (
      item.progresses[0]?.status !== "completed" &&
      item.progresses[0]?.status !== "in-review"
    ) {
      restricted.value = true;
      path.value = "/hr";
    }
  });
  const id = route.query.task;
  const category = route.query.topic;
  if (id) view.value = "task";
  console.log(
    "!!!!!!:",
    technologyList.tasks.find((item) => item.id === +id)
  );
  if (category === "Technology") {
    currentTask.value = technologyList.tasks.find((item) => item.id === +id);
    topic.value = "Technology";
  }
  if (category === "Logistics") {
    currentTask.value = logisticsList.tasks.find((item) => item.id === +id);
    topic.value = "Logistics";
  }
});

const getTasks = async () => {
  const checklist = await ChecklistAPI.getChecklist();
  const technologyList = checklist.find((task) => task.name === "Technology");
  technologyList.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  const logisticsList = checklist.find((task) => task.name === "Logistics");
  logisticsList.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  allTasks.value = technologyList.tasks.concat(logisticsList.tasks);
  technologyCompleted.value = 0;
  logisticsCompleted.value = 0;
  technologyList.tasks.forEach((task) => {
    if (task.progresses)
      task.status = task.progresses?.find(
        (item) => item.task_id === task.id
      )?.status;
    else task.status = "available";
    if (task.subtasks) {
      task.subtasks.forEach((sub) => {
        sub.status = task.progresses?.find((item) => item.subtask_id === sub.id)
          ?.status
          ? task.progresses?.find((item) => item.subtask_id === sub.id)?.status
          : "available";
      });
    }
    if (task.status === "completed" || task.status === "in-review")
      technologyCompleted.value++;
  });
  logisticsList.tasks.forEach((task) => {
    if (task.progresses)
      task.status = task.progresses?.find(
        (item) => item.task_id === task.id
      )?.status;
    else task.status = "available";
    if (task.subtasks) {
      task.subtasks.forEach((sub) => {
        sub.status = task.progresses?.find((item) => item.subtask_id === sub.id)
          ?.status
          ? task.progresses?.find((item) => item.subtask_id === sub.id)?.status
          : "available";
      });
    }
    if (task.status === "completed" || task.status === "in-review")
      logisticsCompleted.value++;
  });
  technology.value = technologyList.tasks;
  console.log("technology:", technology.value);
  logistics.value = logisticsList.tasks;
  console.log("logistics:", logistics.value);
  if (
    allTasks.value.length ===
      technologyCompleted.value + logisticsCompleted.value &&
    route.query.complete
  )
   if (
    allTasks.value.length ===
      technologyCompleted.value + logisticsCompleted.value 
  )
    showAssistanceButton.value = false
  //  workdayPercentDone.value = `${Math.floor(workdayCompleted.value / workday.value.length * 100)}%`
  //  badgingPercentDone.value = `${Math.floor(badgingCompleted.value / badging.value.length * 100)}%`
};
// const completeTask = async (task, i, column) => {
//   console.log('***task***', task)
//   view.value = 'setting-up'

//   const updatedTask = {
//   task_id: task.id,
//   status: 'in-review'
// }
//     await ChecklistAPI.updateTask(updatedTask)
//     await getTasks()
// }
const view = ref("setting-up");

// const updateStatus = async (task, check, subtask, topic) => {

// if(check==='uncheck'){
//   const updatedTask = {
//     task_id: task.id,
//     status: 'available'
//   }
// const updateSubtask = {
//   subtask_id: subtask.id,
//   status: 'available'
// }
//     await ChecklistAPI.updateTask(updatedTask)
//     await ChecklistAPI.updateSubtask(updateSubtask)
//   }

// if(check==='check'){

// const updateSubtask = {
//   subtask_id: subtask.id,
//   status: 'in-review'
// }
//     await ChecklistAPI.updateSubtask(updateSubtask)
//   }
//     await getTasks()
//     console.log('task:', task)
//     console.log('tecknolo:', technology.value)
//     currentTask.value = allTasks.value.find(item=> item.id===task.id)
// console.log('currentTask:', currentTask.value)
// }

const taskClicked = async (item, category, index) => {
  // currentIndex.value = index
  // currentTask.value = item
  // topic.value = category
  // view.value = 'task'
  await router.push(`/task?task=${item.id}&topic=${category}`);
};
</script>

<style scoped>
.completed {
  margin-top: 20px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 102.1%;
  /* or 16px */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.button-area {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 45px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}

.months-blocks {
  display: flex;
  margin-bottom: 40px;
  column-gap: 20px;
}

.month-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  height: 210px;
  padding: 40px;
  border-radius: 20px;
  background: #dbdede;
}

.month-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 128px;
}
.circle {
  width: 133px;
  height: 133px;
}
.assistance-modal {
  position: fixed;
  left: 43%;
  bottom: 13%;
  z-index: 1000;
  /* transform: translate(-50%, -50%); */
}
.hand {
  margin-bottom: 20px;
}
.restrict-main {
  width: 935px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
}
.restrict-button {
  cursor: pointer;
  width: 195px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* HD Orange */
  margin-top: 70px;
  background: #f96302;
  border-radius: 30px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.restrict-explanation {
  width: 506px;
}
.workday {
  width: 458px;
  height: 230px;
  background: linear-gradient(354.24deg, #f96302 -5.58%, #ff9900 121.2%);
  border-radius: 20px;
}
.explanation {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 127.5%;
  /* or 20px */

  color: #5f6562;
}
.back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
}

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.badging {
  width: 458px;
  height: 230px;
  background: linear-gradient(180deg, #00a1b3 0%, #006873 135.87%);
  border-radius: 20px;
}

.month-number-text {
  width: 193px;
  height: 34px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 102.1%;
  /* or 37px */

  display: flex;
  align-items: center;
  letter-spacing: -0.025em;

  color: #ffffff;
}
.uncheck-modal {
  width: 470px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  flex-direction: column;
  padding: 20px 60px 20px 60px;
  left: 43.5%;
  bottom: 33%;
  z-index: 1000;
  border: solid 1px black;
  /* transform: translate(-50%, -50%); */
}
.tasks-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  margin: 20px 0;
  row-gap: 40px;
}

.month-tasks-block {
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;
  width: 458px;
  height: 100%;
}
.tasks {
  display: flex;
  justify-content: space-between;
}
.month-header {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  color: #ff8217;
  text-transform: uppercase;
}

.month-tasks-subblock {
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
  flex-direction: column;
}

.tasks-block {
  flex: 1;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
}
.button {
  margin: 25px;
}
.bold {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
  /* identical to box height */
  color: #000000;
}
</style>
